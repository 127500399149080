.custom-typeahead .rbt-input-main {
  background-color: #3d3d3d !important;
  color: white !important;
}

.custom-typeahead .rbt-menu {
  background-color: #3d3d3d !important;
}

.custom-typeahead .rbt-menu .dropdown-item {
  color: white !important;
}

.custom-typeahead .rbt-menu .dropdown-item:hover {
  background-color: #575757 !important;
}
.css-153cjoe-button-primary-medium-fullWidth {
  display: none !important;
}
