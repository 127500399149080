/* Theme: Geeks */

// Inter Fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Font Awesome
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css");

// Material Icons
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// Feathers CSS for Before Icon
@import url("../fonts/feather/feather.css");

// Bootstrap functions
@import "~bootstrap/scss/functions";

// User Variables
@import "user-variables";

// theme variables
@import "theme/variables";

//utilities
@import "theme/utilities";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Root
@import "theme/root";

// Theme
@import "theme/theme";

// User
@import "user";
